import request from "../../utils/request";
import axios from "axios";
import { getToken } from "@/utils/auth";

/**货品列表
 *
 * @param params
 * @returns {*}
 */
export function goodsList(params) {
  return request({
    url: "/api/repository/repGoods/list",
    method: "get",
    params: params,
  });
}

/**获取货品信息
 *
 * @param sid
 * @returns {*}
 */
export function getGoodsById(sid) {
  return request({
    url: "/api/repository/repGoods/get/" + sid,
    method: "get",
  });
}
/**获取货品信息
 *
 * @param sid
 * @returns {*}
 */
export function getGoodsBySkuId(sid) {
  return request({
    url: "/repGoods/get-by-sku/" + sid,
    method: "get",
  });
}
/**货品删除
 *
 * @param sid
 * @returns {*}
 */
export function deleteGoodsById(sid) {
  return request({
    url: "/api/repository/repGoods/delete/" + sid,
    method: "delete",
  });
}

/**近似货品
 *
 * @param sid
 * @returns {*}
 */
export function getSimilarGoodsById(sid) {
  return request({
    url: "/api/repository/repGoods/get-similar/" + sid,
    method: "get",
  });
}

/**获取货品配置的标签
 *
 * @param sid
 * @returns {*}
 */
export function getLabelsById(sid) {
  return request({
    url: "/api/repository/repGoods/get-labels/" + sid,
    method: "get",
  });
}

/**获取货品分类列表
 *
 * @returns {*}
 */
export function goodsSortList() {
  return request({
    url: "/api/repository/repGoodsSorts/list_v",
    method: "get",
  });
}

/**
 * 构造树型结构数据
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 */
export function handleTree(data, id, parentId, children) {
  let config = {
    id: id || "id",
    parentId: parentId || "parentId",
    childrenList: children || "children",
  };

  var childrenListMap = {};
  var nodeIds = {};
  var tree = [];

  for (let d of data) {
    let parentId = d[config.parentId];
    if (childrenListMap[parentId] == null) {
      childrenListMap[parentId] = [];
    }
    nodeIds[d[config.id]] = d;
    childrenListMap[parentId].push(d);
  }

  for (let d of data) {
    let parentId = d[config.parentId];
    if (nodeIds[parentId] == null) {
      tree.push(d);
    }
  }

  for (let t of tree) {
    adaptToChildrenList(t);
  }

  function adaptToChildrenList(o) {
    if (childrenListMap[o[config.id]] !== null) {
      o[config.childrenList] = childrenListMap[o[config.id]];
    }
    if (o[config.childrenList]) {
      for (let c of o[config.childrenList]) {
        adaptToChildrenList(c);
      }
    }
  }
  return tree;
}

/**查询分类下拉树结构
 *
 * @returns {*}
 */
export function sortTreeSelect() {
  return request({
    url: "/api/repository/repGoodsSorts/sortTree",
    method: "get",
  });
}

/**添加货品
 *
 * @param data
 * @returns {*}
 */
export function addGoods(data) {
  return request({
    url: "/api/repository/repGoods/add",
    method: "post",
    data: data,
  });
}

/**修改货品
 *
 * @param data
 * @returns {*}
 */
export function editGoods(data) {
  return request({
    url: "/api/repository/repGoods/update",
    method: "put",
    data: data,
  });
}

/**字典表
 *
 * @param dictType
 * @returns {*}
 */
export function loadGoodsDict(dictType) {
  return request({
    url: "/api/repository/dict/data/type/" + dictType,
    method: "get",
  });
}

/**回显数据字典
 *
 * @param datas
 * @param value
 * @returns {string}
 */
export function selectDictLabel(datas, value) {
  if (value === undefined) {
    return "";
  }
  var actions = [];
  Object.keys(datas).some((key) => {
    if (datas[key].sid == "" + value) {
      actions.push(datas[key].ssort);
      return true;
    }
  });
  if (actions.length === 0) {
    actions.push(value);
  }
  return actions.join("");
}

/**获取单据文件url
 *
 * @param businessId
 * @returns {*}
 */
export function getFileUrls(businessId) {
  return request({
    url: "/api/file/list/" + businessId,
    method: "get",
  });
}

export function exportGoods(param) {
  exportExcel("/api/repository/repGoods/export", param, `货品列表_${new Date().getTime()}.xlsx`);
}

export function importGoodsTemplate() {
  exportExcel("/api/repository/repGoods/importTemplate", {}, `货品导入模板.xlsx`);
}

export function importInventorySheetTemplate() {
  exportExcel("/api/repository/inventory/importTemplate", {}, `期初入库模板.xlsx`);
}

export function importInventoryOderOutTemplate(title) {
  exportExcel("/api/repository/repInventoryOrderOut/importTemplate", {}, `${title}模板.xlsx`);
}

export function importInventoryDboutTemplate() {
  exportExcel("/api/repository/repInventoryOrderOut/importDbTemplate", {}, `调拨出库模板.xlsx`);
}


export function exportStoreGoods(params) {
  exportExcel("/api/repository/store/exportData", params, `${params.storeName}_${new Date().getTime()}.xlsx`);
}

export function exportInventorySheet(params) {
  exportExcel("/api/repository/inventory/exportData", params, `${params.storeName}_${new Date().getTime()}.xlsx`);
}

export function exportInventoryDetail(params) {
  exportExcel("/api/repository/inventory/exportDataDetail", params, `${params.csku}_${new Date().getTime()}.xlsx`);
}

export function exportInventoryOrderDetailInOne(params) {
  exportExcel("/api/repository/repInventoryOrderDetailIn/exportDataDetail", params, `单个入库单明细_${new Date().getTime()}.xlsx`);
}

export function exportInventoryOrderDetailOutOne(params) {
  exportExcel("/api/repository/repInventoryOrderDetailOut/exportDataDetail", params, `单个出库单明细_${new Date().getTime()}.xlsx`);
}

export function exportInventoryOrderIn(params) {
  exportExcel("/api/repository/repInventoryOrderIn/exportData", params, `入库单_${new Date().getTime()}.xlsx`);
}

export function exportInventoryOrderOut(params) {
  exportExcel("/api/repository/repInventoryOrderOut/exportData", params, `出库单_${new Date().getTime()}.xlsx`);
}

export function exportInventoryOrderInDetail(params) {
  exportExcel("/api/repository/repInventoryOrderDetailIn/exportInDetail", params, `入库单明细_${new Date().getTime()}.xlsx`);
}

export function exportInventoryOrderOutDetail(params) {
  exportExcel("/api/repository/repInventoryOrderDetailOut/exportOutDetail", params, `出库单明细_${new Date().getTime()}.xlsx`);
}

export function exportInventoryDbout(params) {
  exportExcel("/api/repository/repInventoryOrderOut/exportInventoryDbout", params, `异常数据_${new Date().getTime()}.xlsx`);
}

export function exportOrderOutList(params) {
  exportExcel("/api/repository/repGoods/exportOrderOutList", params, `商品分析出库单导出_${new Date().getTime()}.xlsx`);
}
export function exportSurplus(params) {
  exportExcel("/api/repository/repGoods/exportSurplus", params, `库存分布_${new Date().getTime()}.xlsx`);
}
//导出
export function exportExcel(url, params, fileName) {
  axios
    .post(url, params, {
      transformRequest: [
        (params) => {
          return tansParams(params);
        },
      ],
      headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: getToken() },
      responseType: "blob",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    });
}

export function tansParams(params) {
  let result = "";
  for (const propName of Object.keys(params)) {
    const value = params[propName];
    var part = encodeURIComponent(propName) + "=";
    if (value !== null && value !== "" && typeof value !== "undefined") {
      if (typeof value === "object") {
        for (const key of Object.keys(value)) {
          if (value[key] !== null && value[key] !== "" && typeof value[key] !== "undefined") {
            let params = propName + "[" + key + "]";
            var subPart = encodeURIComponent(params) + "=";
            result += subPart + encodeURIComponent(value[key]) + "&";
          }
        }
      } else {
        result += part + encodeURIComponent(value) + "&";
      }
    }
  }
  return result;
}
